export default {
  opening: {
    title: [
      "诚意邀请您成为我们的咖啡爱好者",
      "如果您对精品咖啡有兴趣，就别犹豫了。立刻申请 成为我们的咖啡爱好者(现在申请将送你一套V60手冲咖啡工具)",
    ],
    description: [
      "We Sincerely invite you to be our coffee lover",
      "If you are interested in boutique coffee, please do not hesitate to apply as our coffee lover (Sign up now to receive a free V60 coffee making tools)",
    ],
  },
  questions: [
    {
      name: "referral",
      title: ["介绍人，咖啡爱好者号码"],
      description: ["Referral (Coffee lover ID)"],
      placeholder: "咖啡爱好者号码... (Optional)",
      type: "text",
      optional: true,
    },
    {
      name: "name",
      title: ["您的名字是...？"],
      description: ["What is your name?"],
      placeholder: "名字...",
      type: "text",
    },
    {
      name: "email",
      title: ["您好 <name>！请问您的电邮地址是…?"],
      description: ["<name>, what is your email address?"],
      placeholder: "电邮地址...",
      type: "email",
    },
    {
      name: "phone",
      title: ["<name>，您的电话号码是什么？"],
      description: ["<name>, what is your phone number?"],
      placeholder: "电话号码...",
      type: "tel",
    },
    {
      name: "coffee",
      title: [
        "<name>, 请问你所需要的咖啡品类是。。？(最少选二盒)",
        "*运输费 RM10",
      ],
      description: [
        "<name>, which coffee would you like..? (Please choose at least 2)",
        "*Shipping cost RM10",
      ],
      options: [
        "ETHIOPIA (十包一盒) — RM49",
        "BRAZIL (十包一盒) — RM49",
        "COLOMBIA (十包一盒) — RM49",
        "INDONESIA (十包一盒) — RM49",
      ],
      type: "checkbox",
      min: 2,
    },
    {
      name: "cardNo",
      title: ["<name>, 请问你的信用卡银行 / 号码是。。？"],
      description: ["<name>, what is your credit card number?"],
      placeholder: "Credit card number...",
      type: "text",
    },
    {
      name: "cardType",
      title: ["<name>, 请问你的Card type 是。。？"],
      description: ["<name>, what is your credit card type?"],
      type: "dropdown",
      options: ["Master", "Visa", "Debit Card"],
    },
    {
      name: "cardExpiry",
      title: ["<name>, 请问你的Card expiry 是。。？"],
      description: ["<name>, what is your card expiry date??"],
      placeholder: "Expiry date...",
      type: "text",
    },
    {
      name: "icNo",
      title: ["<name>, 请问你的身份证号码是。。？"],
      description: ["<name>, what is your ic number?"],
      placeholder: "XXXXXX-XX-XXXX",
      type: "text",
    },
    {
      name: "deliveryDate",
      title: ["<name>, 请问你的每月寄货日期是。。？1号/15号"],
      description: ["<name>, when do you want the delivery date to be?"],
      type: "dropdown",
      options: ["1st of every month", "15th of every month"],
    },
    {
      name: "deliveryAddress",
      title: ["<name>, 请问你的收货地址是。。？"],
      description: ["<name>, what is your delivery address?"],
      placeholder: "Address...",
      type: "text",
    },
    {
      name: "agreeTnC",
      title: ["信用卡过账了不能退款。", "请问你同意以上的信息吗？"],
      description: [
        "We do not refund once the credit card is charged.",
        "Do you agree with the above statement?",
      ],
      type: "dropdown",
      options: ["Yes"],
    },
  ],
  ending: {
    title: ["感谢您的申请。 一旦您入围，我们将与您联系。"],
    description: [
      "Thank you for your application. We will contact you once you are shortlisted.",
    ],
  },
};
