import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import pages from './navigation/pages';
import Main from './screens/Main';


function App() {
  return (
    <Switch>
      <Route exact path={pages.main.path} component={Main} />
      <Route render={() => <Redirect to={'/'} />} />
  </Switch>
  );
}

export default App;
