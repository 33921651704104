import React from 'react';
import { Navbar, NavbarBrand } from '@bootstrap-styled/v4';
import styled from 'styled-components';

import logo from '../../assets/brand.png';
import theme from '../../utils/constants'

const S = {}

S.Navbar = styled(Navbar)`
  && {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1030;
  }
`

S.NavbarBrand = styled(NavbarBrand)`
  && {
    display: flex;
    max-height: ${theme.navHeight};
    width: fit-content;
  }
`

S.NavbarBrandImg = styled.img`
  max-height: ${theme.navHeight};
`

const Navigation = () => (
  <S.Navbar>
    <S.NavbarBrand href="/" className="py-0">
      <S.NavbarBrandImg src={logo} alt="Asanini Logo"/>
    </S.NavbarBrand>
  </S.Navbar>
)

export default Navigation
