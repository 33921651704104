export default {
  breakpoints: {
    xs: '@media (min-width: 0px)', // Default, useless
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    xxl: '@media (min-width: 1452px)',
  },
  colors: {
    tansparent: 'rgba(255,255,255,0)',
    brand: '#41CDFF',
    lightBrand: '#F6E8E8',
    gray: '#666666',
    lightGray: '#CCCCCC',
    lighterGray: '#EEEEEE',
    darkGray: '#333333',
    black: '#111111',
    white: '#ffffff',
  },
  // fonts: {
  //   sans:
  //     'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  //   mono:
  //     'Menlo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  //   header:
  //     'Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  //   article:
  //     'Unna, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  // },
  fontWeights: {
    sans: {
      thin: 300,
      normal: 400,
      bold: 700,
    },
    header: {
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    mono: {
      normal: 400,
    },
    article: {
      normal: 400,
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96, 144],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  'navHeight': '64px',
  '$theme-colors': {
    primary: '#3AB9E6',
    danger: '#ff4136',
  },
  '$btn-primary-bg': '#3AB9E6',
  '$headings-font-family':
    'Raleway, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  '$headings-font-weight': '600',
  '$headings-line-height': '1.5',
  '$headings-color': 'inherit',
  '$font-family-sans-serif':
    'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  '$font-size-h1': '40px',
  '$font-size-h2': '30px',
  '$font-size-h3': '24px',
  '$font-size-h4': '20px',
  '$font-size-h5': '18px',
  '$font-size-h6': '16px',
  '$line-height-base': '1.5',
  '$table-cell-padding': '0',
  '$table-border-width': '0',
  '$input-padding-x-sm': '1rem',
  '$input-padding-y-sm': '.75rem',
  $spacer: '1.25rem',
  '$enable-rounded': true,
}
