import React from 'react';

import Layout from '../components/Common/Layout'
import Questionaire from '../components/Section/Questionaire'
import './main.scss';
import questions from '../data/questions'


const Main = ({  }) => {

  return (
    <Layout>
      <Questionaire questions={questions}/>
    </Layout>
  );
}

export default Main;
