import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "@bootstrap-styled/v4";
import styled from "styled-components";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";

import FormIntro from "../Block/FormIntro";
import FormOutro from "../Block/FormOutro";
import Question from "../Block/Question";
import theme from "../../utils/constants";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const S = {};

S.Container = styled(Container)`
  height: ${(props) => (props.height ? `${props.height}px` : "100vh")};
  width: 100%;
  margin-top: -${theme.navHeight};
  display: flex;
`;

S.VerticalContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

const Questionaire = ({ questions }) => {
  const [height, setHeight] = useState(0);
  const [stage, setStage] = useState("intro");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [response, setResponse] = useState({});

  useEffect(() => {
    setHeight(
      Math.max(window.innerHeight, document.documentElement.clientHeight)
    );

    window.onresize = () => {
      setHeight(
        Math.max(window.innerHeight, document.documentElement.clientHeight)
      );
    };
  }, []);

  const handleSubmit = () => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "career",
        ...response,
      }),
    })
      .then(() => setStage("outro"))
      .catch((error) => alert(error));
  };

  const renderContent = () => {
    switch (stage) {
      case "intro":
        return (
          <FormIntro
            title={questions.opening.title}
            description={questions.opening.description}
            onNext={() => {
              setStage("question");
            }}
          />
        );
      case "question":
        const question = questions.questions[questionIndex];
        return (
          <Question
            name={question.name}
            title={question.title}
            description={question.description}
            type={question.type}
            first_name={response["name"] ? response["name"] : ""}
            options={question.options}
            onChange={(e) => {
              let value = e.target.value;
              if (question.type === "checkbox") {
                const checked = e.target.checked;
                setResponse((response) => {
                  let currValue = [];
                  if (response[question.name]) {
                    currValue = response[question.name];
                  }

                  if (checked) {
                    currValue.push(value);
                  } else {
                    currValue = currValue.filter((item) => {
                      return item !== value;
                    });
                  }
                  console.log("end", currValue);
                  return Object.assign({}, response, {
                    [question.name]: [...new Set(currValue)],
                  });
                });
                return;
              }

              if (question.type === "tel") {
                if (value[0] !== "+") {
                  value = "+" + value;
                }
                value = new AsYouType().input(value);
              }
              setResponse(
                Object.assign({}, response, { [question.name]: value })
              );
            }}
            value={response[question.name] ? response[question.name] : ""}
            placeholder={question.placeholder}
            canPrev={questionIndex !== 0}
            onPrev={() => {
              setQuestionIndex(questionIndex - 1);
            }}
            canNext={(() => {
              if (question.optional) {
                return true;
              }

              switch (question.type) {
                case "text":
                case "number":
                case "dropdown":
                  return response[question.name];
                case "email":
                  return new RegExp(
                    "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"
                  ).test(response[question.name]);
                case "tel":
                  try {
                    return parsePhoneNumberFromString(
                      response[question.name]
                    ).isValid();
                  } catch (TypeError) {
                    return false;
                  }
                case "checkbox":
                  if (!response[question.name]) return false;
                  if (
                    question.min &&
                    response[question.name].length < question.min
                  )
                    return false;
                  if (
                    question.max &&
                    response[question.name].length > question.max
                  )
                    return false;
                  return true;
                default:
                  return false;
              }
            })()}
            onNext={() => {
              if (questions.questions.length - 1 === questionIndex) {
                handleSubmit();
              } else {
                setQuestionIndex(questionIndex + 1);
              }
            }}
            isLast={questions.questions.length - 1 === questionIndex}
            paginationText={`${questionIndex + 1}/${
              questions.questions.length
            }`}
          />
        );
      case "outro":
        return (
          <FormOutro
            title={questions.ending.title}
            description={questions.ending.description}
          />
        );
      default:
        return null;
    }
  };

  return (
    <S.Container height={height}>
      <S.VerticalContainer>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>{renderContent()}</Col>
        </Row>
      </S.VerticalContainer>
    </S.Container>
  );
};

export default Questionaire;
