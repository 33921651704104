import React from 'react';
import { H1, H4 } from '@bootstrap-styled/v4';
import styled from 'styled-components';

import theme from '../../utils/constants'


const S = {}

S.TitleBlock = styled.div`

  margin-bottom: 1rem;

  ${theme.breakpoints.lg} {
    margin-bottom: 1.5rem;
  }
`

S.Title = styled(H1)`
  font-size: ${theme.fontSizes[4]}px;
  text-align: center;

  & span {
    display: block;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[5]}px;
  }
`

S.DescriptionBlock = styled.div`
  margin-bottom: 2rem;

  ${theme.breakpoints.lg} {
    margin-bottom: 4rem;
  }
`

S.Description = styled(H4)`
  color: ${theme.colors.gray};
  font-size: ${theme.fontSizes[2]}px;
  text-align: center;
  font-weight: 500;

  & span {
    display: block;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`

const FormOutro = ({
  title,
  description,
}) => (
  <>
    <S.TitleBlock>
      <S.Title>
        {title && title.map(line => (
          <span>{line}</span>
        ))}
      </S.Title>
    </S.TitleBlock>
    <S.DescriptionBlock>
      <S.Description>
        {description && description.map(line => (
          <span>{line}</span>
        ))}
      </S.Description>
    </S.DescriptionBlock>
  </>
)

export default FormOutro
