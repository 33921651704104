import React from "react";
import {
  H1,
  H4,
  P,
  Form,
  FormGroup,
  Input,
  Option,
  Button,
} from "@bootstrap-styled/v4";
import styled from "styled-components";

import theme from "../../utils/constants";
import Label from "@bootstrap-styled/v4/lib/Label";

const S = {};

S.TitleBlock = styled.div`
  ${theme.breakpoints.lg} {
    margin-bottom: 0.5rem;
  }
`;

S.Title = styled(H1)`
  font-size: ${theme.fontSizes[4]}px;
  text-align: center;

  & span {
    display: block;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[5]}px;
  }
`;

S.DescriptionBlock = styled.div`
  margin-bottom: 1.5rem;

  ${theme.breakpoints.lg} {
    margin-bottom: 2rem;
  }
`;

S.Description = styled(H4)`
  color: ${theme.colors.gray};
  font-size: ${theme.fontSizes[2]}px;
  text-align: center;
  font-weight: 500;

  & span {
    display: block;
  }

  ${theme.breakpoints.lg} {
    text-align: left;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`;

S.InputBlock = styled.div`
  margin-bottom: 3rem;

  ${theme.breakpoints.lg} {
    margin-bottom: 4rem;
  }
`;

S.ActionBlock = styled.div`
  display: block;
  text-align: center;
`;

S.PrevButton = styled(Button)`
  float: left;
`;

S.PaginationText = styled(P)`
  float: center;
  margin-bottom: 0;
  padding-top: 0.5rem;
`;

S.NextButton = styled(Button)`
  float: right;

  &&.disabled {
    color: ${theme.colors.lightGray};
    border-color: ${theme.colors.lightGray};
  }

  &&.disabled:hover {
    background-color: unset;
  }
`;

const Question = ({
  name,
  title,
  description,
  type,
  first_name,
  options,
  onChange,
  value,
  placeholder,
  canPrev,
  onPrev,
  canNext,
  onNext,
  isLast,
  paginationText,
}) => {
  const renderInput = (type, name, value, placeholder, onChange, options) => {
    switch (type) {
      case "text":
      case "number":
      case "email":
      case "tel":
        return (
          <Input
            id={name}
            name={name}
            size="md"
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
      case "dropdown":
        return (
          <Input
            id={name}
            name={name}
            size="md"
            type="select"
            value={value}
            onChange={onChange}
          >
            <Option value="">请选择</Option>
            {options.map((option, index) => (
              <Option value={option} key={index}>
                {option}
              </Option>
            ))}
          </Input>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <S.TitleBlock>
        <S.Title>
          {title &&
            title.map((line, index) => (
              <span key={index}>{line.replace("<name>", first_name)}</span>
            ))}
        </S.Title>
      </S.TitleBlock>
      <S.DescriptionBlock>
        <S.Description>
          {description &&
            description.map((line, index) => (
              <span key={index}>{line.replace("<name>", first_name)}</span>
            ))}
        </S.Description>
      </S.DescriptionBlock>
      <S.InputBlock>
        <Form>
          {type === "checkbox" ? (
            <>
              {options.map((option, index) => (
                <FormGroup check key={index}>
                  <Label check>
                    <Input
                      type={type}
                      name={name}
                      value={option}
                      onClick={onChange}
                    />
                    {option}
                  </Label>
                </FormGroup>
              ))}
            </>
          ) : (
            <FormGroup>
              {renderInput(type, name, value, placeholder, onChange, options)}
            </FormGroup>
          )}
        </Form>
      </S.InputBlock>
      <S.ActionBlock>
        {canPrev && (
          <S.PrevButton onClick={onPrev} color="secondary">
            Previous
          </S.PrevButton>
        )}
        <S.NextButton outline onClick={onNext} disabled={!canNext}>
          {isLast ? "Submit" : "Next"}
        </S.NextButton>
        <S.PaginationText>{paginationText}</S.PaginationText>
      </S.ActionBlock>
    </>
  );
};

export default Question;
